import React from "react";
import styled from "styled-components";

import Header from "../header";
import { StyledButton } from "../elements";

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: #141821;
  height: 100%;
  width: 100%;
`;

const HeaderContainer = styled.div`
  padding: 0 16px;
`;
const GridContainer = styled.div`
  margin: 0 auto;
  max-width: 720px;
`;
const PlayersGrid = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fill, 100px);
  grid-gap: 8px;
  justify-content: center;
  align-content: flex-start;

  /* boring properties: */
  list-style: none;
  margin: 16px auto;
  padding: 0;
`;

const PlayerContainer = styled.li`
  display: block;
  cursor: ${(props) => (props.disabled ? "auto" : "pointer")};
`;

const PlayerImage = styled.img`
  margin: auto;
  padding: 0;
  vertical-align: middle;
  background-color: #2b2b2b;
  border: 4px solid
    ${(props) =>
      props.selected
        ? props.teamSide === "away"
          ? "#e8c547"
          : "#cdedf6"
        : "#2b2b2b"};
  border-radius: 50%;
  height: 48px;
  width: 48px;
  text-align: center;
  font-size: 1.6rem;
`;

const Name = styled.div`
  padding-top: 8px;
  text-transform: uppercase;
  text-align: center;
  font-size: 0.7rem;
  font-weight: 500;
`;

const PlayerSelection = ({
  players,
  fields,
  append,
  remove,
  team_side,
  limit = 2,
  toggle,
}) => {
  const selectedCount = fields.filter((o) => o.team_side == team_side).length;
  const limitReached = selectedCount >= limit;
  const selectedIds = fields.map((item, index) => parseInt(item.player_id));

  return (
    <>
      <Container>
        <HeaderContainer>
          <Header activatorClose={toggle} />
        </HeaderContainer>
        <p>
          {limitReached
            ? `${team_side.capitalize()} team ready`
            : `Choose up to ${limit - selectedCount} player(s)`}
        </p>
        <GridContainer>
          <PlayersGrid>
            {players.map((player, i) => {
              const isSelected = selectedIds.includes(player.id);
              const ix = isSelected && selectedIds.indexOf(player.id);
              const teamSide = isSelected && fields[ix].team_side;
              return (
                <PlayerContainer
                  key={i}
                  disabled={isSelected && teamSide != team_side}
                  onClick={() => {
                    if (isSelected && teamSide == team_side) {
                      remove(ix);
                    } else if (!limitReached && !isSelected) {
                      append({
                        player_id: player.id,
                        name: player.name,
                        team_side: team_side,
                        photo_url: player.photo_url,
                      });
                    }
                  }}
                >
                  <PlayerImage
                    src={player.photo_url}
                    selected={isSelected}
                    disabled={isSelected && teamSide != team_side}
                    teamSide={isSelected && teamSide}
                  />
                  <Name>{player.name}</Name>
                </PlayerContainer>
              );
            })}
          </PlayersGrid>
        </GridContainer>
        <StyledButton onClick={toggle}>Select players</StyledButton>
      </Container>
    </>
  );
};
export default PlayerSelection;
