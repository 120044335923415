import React from "react";
import styled from "styled-components";

import { Link } from "gatsby";
import { useSWRwithParams, swrError, swrLoading } from "../helpers";
import GameFIFA from "../../images/games/fifa.png";
import GameFoosball from "../../images/games/foosball.png";
import GamePingpong from "../../images/games/pingpong.png";
import { StyledButtonLink, SubTitle, Title } from "../elements";

import { ReactComponent as Placeholder } from "../../images/placeholder.svg";
import { ReactComponent as IconPlus } from "../../images/icons/plus-square.svg";
import { ReactComponent as IconJoin } from "../../images/icons/join.svg";

const League = styled(Link)`
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: var(--white);
  padding: 8px;
  width: 100%;
  max-width: 400px;
  margin: 12px auto;
  box-sizing: border-box;
  border: 8px solid var(--white);
  border-radius: var(--border-radius);

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      box-shadow: 0 2px 4px 0 rgba(0, 38, 97, 0.15),
        0 10px 20px -10px rgba(0, 38, 97, 0.24);
      border-color: var(--primary);
    }
  }
  text-decoration: none;
`;

const LeagueImage = styled.div`
  svg {
    max-height: 64px;
    max-width: 64px;
  }
  img {
    max-height: 64px;
    max-width: 64px;
  }
`;
const LeagueInfo = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  margin: 16px;
  width: 100%;
  span {
    color: #000;
  }
`;

const ButtonContainer = styled(StyledButtonLink)`
  background-color: #333;
  color: var(--white);
  display: flex;
  margin: 8px auto;
  margin-bottom: 8px;
  padding: 12px;
  max-width: 220px;
  span {
    text-align: left;
    margin: auto 0;
    padding-left: 12px;
    font-weight: 500;
    text-transform: initial;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover,
    &:focus {
      background-color: #000;
      color: #fff;
      svg {
        fill: #fff;
      }
    }
  }
`;
const IconContainer = styled.div`
  min-width: 24px;
  height: 20px;
  margin: auto 0;
  svg {
    height: 20px;
    padding: 0;
    margin: 0;
  }
`;

const LeagueAccount = ({ user }) => {
  const { data, error } = useSWRwithParams("/api/leagues", {
    method: "GET",
    headers: {
      "X-User-Token": user.ya,
    },
  });

  return (
    <>
      <Title>Your Leagues</Title>
      {error ? (
        swrError(error)
      ) : !data ? (
        swrLoading
      ) : (
        <>
          {data.data &&
            data.data.map((league, i) => (
              <League key={`league-${i}`} to={`/leagues/${league.slug}`}>
                <LeagueImage>
                  {league.game_name === "fifa" ? (
                    <img src={GameFIFA} alt="fifa" />
                  ) : league.game_name === "foosball" ? (
                    <img src={GameFoosball} alt="foosball" />
                  ) : league.game_name === "pingpong" ? (
                    <img src={GamePingpong} alt="pingpong" />
                  ) : (
                    <Placeholder />
                  )}
                </LeagueImage>
                <LeagueInfo>
                  <span>{league.name}</span>
                </LeagueInfo>
              </League>
            ))}
        </>
      )}

      <h4>Start or join a new league</h4>
      <div>
        <ButtonContainer to={`/leagues/join`}>
          <IconContainer>
            <IconJoin />
          </IconContainer>
          <span>Join an existing league</span>
        </ButtonContainer>
      </div>
      <div>
        <ButtonContainer to={`/leagues/new`}>
          <IconContainer>
            <IconPlus />
          </IconContainer>
          <span>Start a new league</span>
        </ButtonContainer>
      </div>
    </>
  );
};

export default LeagueAccount;
