import React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { useAsyncFn } from "react-use";
import axios from "axios";

import {
  Title,
  TextInput,
  StyledButton,
  StyledButtonLink,
  ButtonContainer,
} from "../elements";

const Error = styled.div`
  color: #da4167;
  margintop: ".5rem";
`;

const Select = styled.select`
  margin: 0 auto;
  position: relative;
  width: 100%;
  display: block;
  max-width: 300px;
  font-size: 1rem;
  padding: 12px;
  border: 1px solid;
  border-radius: var(--border-radius-box);
  appearance: none;
  -webkit-appearance: none;
`;
const SelectDiv = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
  max-width: 300px;
  cursor: pointer;
  ::before,
  ::after {
    font-size: 1.4rem;
    content: "\\25BE";
    position: absolute;
    color: var(--primary);
    pointer-events: none;
    bottom: 4px;
    right: 12px;
    z-index: 5;
  }
  ::before {
    transform: scaleY(-1);
    bottom: 8px;
  }
`;

const LeagueForm = ({ user }) => {
  const { register, handleSubmit, errors } = useForm({
    mode: "onChange",
    submitFocusError: false,
    defaultValues: {
      playerName: user.displayName && user.displayName.split(" ")[0],
    },
  });

  const [state, submitData] = useAsyncFn(
    async (data) => {
      const response = await axios.post("/api/leagues/create", data, {
        headers: {
          "X-User-Token": user.ya,
        },
      });
      return response;
    },
    [user]
  );

  const onSubmit = (data) => submitData(data);

  return (
    <>
      <Title>Start a new league</Title>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <p>Choose a league name</p>
          <TextInput
            type="text"
            placeholder="League name"
            name="leagueName"
            ref={register({
              required: "This field is required",
              pattern: {
                value: /^[a-zA-Z\x7f-\xff0-9 \-_!?[\]|.]+$/i,
                message: "Only use letters or numbers plz",
              },
              minLength: {
                value: 3,
                message: "Use at least 3 characters",
              },
              maxLength: {
                value: 48,
                message: "Use less than 48 characters",
              },
            })}
          />
          {errors.leagueName && <Error>{errors.leagueName.message}</Error>}
        </div>

        <div>
          <p>Choose your player name</p>
          <TextInput
            type="text"
            placeholder="Player name"
            name="playerName"
            ref={register({
              required: "This field is required",
              pattern: {
                value: /^[a-z0-9]+$/i,
                message: "Only use lowercase letters or numbers plz",
              },
              minLength: {
                value: 3,
                message: "Use at least 3 characters",
              },
            })}
          />
          {errors.playerName && <Error>{errors.playerName.message}</Error>}
        </div>

        <SelectDiv>
          <p>Choose a league type</p>
          <Select
            name="gameName"
            ref={register({
              required: "This field is required",
            })}
          >
            <option value="fifa">FIFA</option>
            <option value="foosball">Foosball</option>
            <option value="pingpong">Table tennis</option>
          </Select>
          {errors.gameName && <Error>{errors.gameName.message}</Error>}
        </SelectDiv>

        <div>
          <p>Enter your invite token</p>
          <TextInput
            type="text"
            placeholder="Invite token"
            name="inviteToken"
            ref={register({
              required: "This field is required",
              pattern: {
                value: /^[a-z|\d]+$/i,
                message: "Invalid token format",
              },
              minLength: {
                value: 32,
                message: "Invalid token format",
              },
              maxLength: {
                value: 32,
                message: "Invalid token format",
              },
            })}
          />
          {errors.inviteToken && <Error>{errors.inviteToken.message}</Error>}
        </div>

        <div>
          {state.loading ? (
            <div>Submitting...</div>
          ) : state.error ? (
            <>
              <div>Error: {state.error.message}</div>
              <StyledButton
                type="submit"
                disabled={Object.keys(errors).length > 0}
              >
                Submit
              </StyledButton>
            </>
          ) : state.value && state.value.status === 200 ? (
            <>
              <div>League {state.value.data.data.league.name} created</div>
              <ButtonContainer>
                <StyledButtonLink
                  to={`/leagues/${state.value.data.data.league.slug}`}
                >
                  Go to league
                </StyledButtonLink>
              </ButtonContainer>
              <ButtonContainer>
                <StyledButton
                  onClick={() =>
                    typeof history !== "undefined" && history.go(-1)
                  }
                >
                  Return home
                </StyledButton>
              </ButtonContainer>
            </>
          ) : (
            <StyledButton
              type="submit"
              disabled={Object.keys(errors).length > 0}
            >
              Submit
            </StyledButton>
          )}
        </div>
      </form>
    </>
  );
};

export default LeagueForm;
