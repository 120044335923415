import React from "react";
import styled from "styled-components";
import { useSWRwithParams, swrError, swrLoading } from "../helpers";
import { SubTitle, Badge, UserImage } from "../elements";

const PlayersList = styled.ul`
  margin: 0 auto;
  margin-bottom: 16px;
  padding: 0;
  color: var(--white);
  background-color: #2b2b2b;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  text-align: center;
  max-width: 600px;
  justify-content: space-between;

  li {
    display: flex;
    padding: 16px;
    &:not(:last-child) {
      border-bottom: 1px dotted var(--primary);
    }
  }
  .image {
    width: 32px;
    margin-right: 8px;
    img {
      margin: auto;
      vertical-align: middle;
    }
  }
`;
const PlayerName = styled.div`
  text-align: left;
  width: 50%;
  margin: auto;
`;

const BadgeContainer = styled.div`
  width: 50%;
  div {
    cursor: pointer;
    margin-right: 0;
  }
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      div {
        color: #000;
        text-decoration: none;
        background: var(--white);
      }
    }
  }
`;

const Players = ({ players, user, leagueSlug, revalidate, ownerId }) => {
  const [actions, setActionState] = React.useState({});
  const togglePlayer = (player) => {
    const action = player.is_active ? "deactivate" : "activate";
    setActionState({ id: player.id, state: action.slice(0, -1) + "ing.." });
    fetch(`/api/players/${player.id}/${action}`, {
      method: "GET",
      headers: {
        "X-User-Token": user.ya,
        "x-league-slug": leagueSlug,
      },
    })
      .then((response) => {
        if (!response.ok) {
          return Promise.reject(response);
        }
        // const responseData = response.json()
        revalidate();
        setActionState({});
      })
      .catch((error) => setActionState({ id: player.id, state: "error" }));
  };
  return (
    <>
      <PlayersList>
        {players
          .sort((a, b) => a.id - b.id)
          .map((player, i) => (
            <li key={i}>
              <div className="image">
                <UserImage src={player.photo_url} />
              </div>
              <PlayerName>{player.name.toUpperCase()}</PlayerName>
              <BadgeContainer>
                <Badge
                  onClick={() => ownerId !== player.id && togglePlayer(player)}
                  backgroundColor={
                    actions.id == player.id && actions.state === "error"
                      ? "var(--red)"
                      : player.is_active
                      ? "var(--azure-white)"
                      : "var(--grey-dark)"
                  }
                  bold={true}
                >
                  <span>
                    {actions.id == player.id
                      ? actions.state
                      : player.is_active
                      ? "ACTIVE"
                      : "INACTIVE"}
                  </span>
                </Badge>
              </BadgeContainer>
            </li>
          ))}
      </PlayersList>
    </>
  );
};

const ManagePlayers = ({ leagueSlug, user }) => {
  const { data, error, revalidate } = useSWRwithParams(`/api/players/all`, {
    method: "GET",
    headers: {
      "X-User-Token": user.ya,
      "x-league-slug": leagueSlug,
    },
  });

  return (
    <>
      {error ? (
        swrError(error)
      ) : !data ? (
        swrLoading
      ) : !(data && data.data.current_player.role === "owner") ? (
        <p>Unauthorized</p>
      ) : (
        <>
          <SubTitle>Players</SubTitle>
          <Badge backgroundColor="var(--grey-x-dark)">
            {data.data.players.length} registered players
          </Badge>
          <Players
            players={data.data.players}
            ownerId={data.data.current_player.id}
            user={user}
            leagueSlug={leagueSlug}
            revalidate={revalidate}
          />
        </>
      )}
    </>
  );
};

export default ManagePlayers;
