import React from "react";
import styled from "styled-components";
import { useToggle } from "react-use";
import { Link, navigate } from "gatsby";
import firebase from "gatsby-plugin-firebase";

import { ReactComponent as Logo } from "../images/logo-light.svg";
import { ReactComponent as ArrowLeft } from "../images/icons/arrow-left.svg";
import { ReactComponent as Close } from "../images/icons/close.svg";

const HeaderBar = styled.nav`
  display: flex;
  justify-content: space-between;
  /* position: sticky; */
  /* top: 0; */
  z-index: 110;
  background-color: var(--primary-dark):
  align-items: center;
`;

const FloatDivLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 40%;
  padding: 0;
  margin: 0;

  div {
    padding: 16px;
    padding-left: 0;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    path {
      stroke: #fff;
      fill: none;
    }
    vertical-align: middle;
    margin: 0;

    @media (hover: hover) and (pointer: fine) {
      &:hover,
      &:focus {
        path {
          stroke: #757aff;
          fill: none;
        }
      }
    }

    svg {
      height: 24px;
      width: 24px;
      margin: 0;
      padding: 0;
    }

    @media (min-width: 768px) {
      padding-left: 16px;
    }
  }
`;

const FloatDivRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 40%;
`;

const HeaderBarLogo = styled(Link)`
  display: block;
  flex-grow: 1;

  text-align: center;
  margin: 0 auto;
`;

const UserContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 16px;
  padding-right: 16px;
  cursor: pointer;

  span {
    display: none;

    @media (min-width: 768px) {
      display: block;
      margin: 0 4px;
      padding: 0;
    }
  }
`;
const UserImage = styled.img`
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: inline-block;
`;

const PopOver = styled.div`
  position: relative;
  color: #000;
  font-weight: 600;

  .popover-content {
    min-width: 144px;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding-box;
    background-clip: padding-box;
    position: absolute;
    right: 0;
    margin-top: 32px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    outline: 0;
  }

  .popover-content::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    right: 10px;
  }
  .popover-section {
    border-bottom: 1px solid #e2e5eb;
  }

  .popover-link {
    padding: 8px 6px;
    a {
      color: #758093;
      text-decoration: none;
    }
  }
  .popover-link:hover {
    background: #f9fbfe;
    color: #0a101a;
    outline: 0;
  }
`;

const NavUser = ({ user }) => {
  const [on, toggle] = useToggle(false);

  const signOutUser = () => {
    firebase
      .auth()
      .signOut()
      .then(function () {
        // Sign-out successful.
        // Go back home
        navigate("/leagues");
      })
      .catch(function (error) {
        // An error happened.
      });
  };

  return (
    <>
      <UserContainer onClick={toggle}>
        <UserImage src={user.photoURL} />
        <span>{user.displayName && user.displayName.split(" ")[0]}</span>
      </UserContainer>
      {on ? (
        <PopOver>
          <div className="popover-content">
            <div className="popover-section">
              <div className="popover-link">
                <Link onClick={toggle} to="/leagues">
                  My Leagues
                </Link>
              </div>
              <div className="popover-link">
                <Link onClick={toggle} to="/leagues/account">
                  My Account
                </Link>
              </div>
            </div>
            <div>
              <div className="popover-link">
                <a href="/" onClick={signOutUser}>
                  Sign Out
                </a>
              </div>
            </div>
          </div>
        </PopOver>
      ) : null}
    </>
  );
};

const Header = ({
  showBack = false,
  headerUrl = "/leagues",
  backUrl = undefined,
  activatorClose = undefined,
  user = undefined,
}) => (
  <>
    <HeaderBar>
      <FloatDivLeft>
        {showBack ? (
          <div
            onClick={() =>
              (backUrl && navigate(backUrl)) ||
              (typeof history !== "undefined" && history.go(-1))
            }
          >
            <ArrowLeft alt="arrow" />
          </div>
        ) : activatorClose ? (
          <div onClick={() => activatorClose(false)}>
            <Close alt="arrow" />
          </div>
        ) : null}
      </FloatDivLeft>
      <HeaderBarLogo to={headerUrl}>
        <Logo className="logo" alt="logo" />
      </HeaderBarLogo>
      <FloatDivRight>{user ? <NavUser user={user} /> : null}</FloatDivRight>
    </HeaderBar>
  </>
);

export default Header;
