import React from "react";
import { useSWRwithParams, swrError, swrLoading } from "../helpers";
import styled from "styled-components";

import GameResult from "./game-result";
import Header from "../header";
import NavigationFloat from "../navigation-float";
import ButtonDeleteGame from "./button-delete-game";
const Container = styled.div`
  padding: 0 16px;
  text-align: center;
  margin-bottom: 80px;
  @media (min-width: 768px) {
    margin-left: 25%;
    margin-bottom: 16px;
  }
`;
const GameProfile = ({ user, leagueSlug, gameId }) => {
  const { data, error } = useSWRwithParams(`/api/games/${gameId}`, {
    method: "GET",
    headers: {
      "X-User-Token": user.ya,
      "x-league-slug": leagueSlug,
    },
  });

  return (
    <>
      <Container>
        <Header showBack={true} user={user} />
        {error ? (
          swrError(error)
        ) : !data ? (
          swrLoading
        ) : (
          <>
            <h1>Game #{gameId}</h1>
            <GameResult
              game={data.data.game}
              leagueSlug={leagueSlug}
              showDetail={true}
            />
            {data.data.current_player.role === "owner" ? (
              <ButtonDeleteGame
                leagueSlug={leagueSlug}
                user={user}
                gameId={gameId}
              />
            ) : null}
          </>
        )}
      </Container>
      <NavigationFloat leagueSlug={leagueSlug} />
    </>
  );
};

export default GameProfile;
