import React from "react";
import { Router } from "@reach/router";

import withAuthorization from "../components/withAuthorization";
import LeaguesHome from "../components/leagues/home";
import LeaguesManage from "../components/leagues/manage";
import LeaguesLanding from "../components/leagues/landing";
import LeaguesSignup from "../components/leagues/signup";
import LeaguesJoin from "../components/leagues/join";
import LeaguesRanking from "../components/leagues/ranking";
import GameProfile from "../components/games/game-profile";
import GameForm from "../components/forms/new-game";
import PlayerForm from "../components/forms/new-player";
import PlayerProfile from "../components/players/player-profile";
import UserAccount from "../components/users/user-account";
import Layout from "../components/layout";
import SEO from "../components/seo";

const Leagues = ({ user }) => (
  <Layout>
    <SEO title="Leagues" />
    <Router>
      <UserAccount path="leagues/account" user={user} />
      <LeaguesManage path="leagues/:leagueSlug/manage" user={user} />
      <LeaguesJoin path="leagues/join" user={user} />
      <LeaguesJoin path="leagues/join/:inviteToken" user={user} />
      <PlayerForm path="leagues/:leagueSlug/new/player" user={user} />
      <GameForm path="leagues/:leagueSlug/new/game" user={user} />
      <LeaguesRanking path="leagues/:leagueSlug/ranking" user={user} />
      <PlayerProfile path="leagues/:leagueSlug/players/:playerId" user={user} />
      <GameProfile path="leagues/:leagueSlug/games/:gameId" user={user} />
      <LeaguesHome path="leagues/:leagueSlug" user={user} />
      <LeaguesSignup path="leagues/new" user={user} />
      <LeaguesLanding path="leagues" user={user} />
    </Router>
  </Layout>
);

export default withAuthorization(Leagues);
