import React from "react";
import styled from "styled-components";
import moment from "moment";

import { useSWRwithParams, swrError, swrLoading } from "../helpers";
import GamesList from "../games/games-list";
import { SeasonBadge } from "../season-badge";
import Header from "../header";
import PlayerStats from "./player-stats";
import EloEvolutionChart from "./elo-evolution-chart";
import NavigationFloat from "../navigation-float";

const daysSinceDate = (strDate) => {
  const date = new Date(strDate);
  const diffTime = Date.now() - date;
  const duration = moment.duration(diffTime);

  return duration.humanize();
};

const Container = styled.div`
  margin-bottom: 80px;
  padding: 0 16px;

  @media (min-width: 768px) {
    margin-left: 25%;
    margin-bottom: 16px;
  }
`;

const PlayerContainer = styled.div`
  margin: 0 auto;
`;

const PlayerHeader = styled.div`
  text-align: center;
  background-color: #2b2b2b;
  border-radius: var(--border-radius);
  padding: 16px;
  color: var(--white);
  h1 {
    color: var(--white);
    text-transform: uppercase
    padding: 16px 0;
    margin: 0;
  }

  p {
    margin: 0;
    padding: 0;
  }

  @media (min-width: 768px) {
    width: 50%;
    margin-right: 16px;
  }
`;

const HistoryText = styled.div`
  display: block;
  font-size: 0.9rem;
  text-align: center;
  color: #ccc;
`;

const Rating = styled.div`
  margin-top: 8px;
  span {
    font-size: 0.8rem;
    color: #ccc;
  }
`;

const DesktopFlexContainer = styled.div`
  @media (min-width: 768px) {
    display: flex;
    justify-content: center;
  }
`;

const PlayerImage = styled.img`
  margin: 0 auto;
  border: 4px solid var(--white);
  border-radius: 50%;
  height: 128px;
  width: 128px;
`;

const Name = styled.div`
  text-transform: uppercase;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 800;
`;

const PlayerProfile = ({ user, leagueSlug, playerId }) => {
  const { data, error } = useSWRwithParams(`/api/players/${playerId}`, {
    method: "GET",
    headers: {
      "x-league-slug": leagueSlug,
      "X-User-Token": user.ya,
    },
  });
  return (
    <>
      <Container>
        <Header showBack={true} user={user} />
        {error ? (
          swrError(error)
        ) : !data ? (
          swrLoading
        ) : (
          <PlayerContainer>
            <DesktopFlexContainer>
              <PlayerHeader>
                <PlayerImage src={data.data.player.photo_url} />
                <Name>{data.data.player.name}</Name>
                <SeasonBadge season_number={data.data.season.season_number} />
                <Rating>
                  <b>PureSkill:</b> {data.data.player_rating.mu.toFixed(1)}
                  <span> ± {data.data.player_rating.sigma.toFixed(0)}</span>
                </Rating>
              </PlayerHeader>
              <PlayerStats data={data.data.player_stats[0]} />
            </DesktopFlexContainer>
            <EloEvolutionChart
              games={data.data.games
                .slice()
                .sort((a, b) => a.played_at - b.played_at)}
              playerRating={data.data.player_rating}
            />
            <div>
              <h2>Latest games</h2>
              <GamesList games={data.data.games} leagueSlug={leagueSlug} />
              <HistoryText>
                Registered {daysSinceDate(data.data.player.created_at)} ago
              </HistoryText>
            </div>
          </PlayerContainer>
        )}
      </Container>
      <NavigationFloat leagueSlug={leagueSlug} />
    </>
  );
};

export default PlayerProfile;
