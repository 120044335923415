// Import FirebaseAuth and firebase.
import React from "react";
import styled from "styled-components";
import firebase from "gatsby-plugin-firebase";
import useAuthState from "./firebase/useAuthState";

import { StyledButton } from "./elements";
import { ReactComponent as LogoGoogle } from "../images/auth/google.svg";
import { ReactComponent as LogoMicrosoft } from "../images/auth/microsoft.svg";
import { ReactComponent as LogoGithub } from "../images/auth/github.svg";
import { ReactComponent as IconLeave } from "../images/icons/leave.svg";
import { ReactComponent as IconLoading } from "../images/oval.svg";

const Message = styled.p`
  padding: 8px;
`;

const ButtonContainer = styled(StyledButton)`
  background-color: #333;
  color: var(--white);
  display: flex;
  margin-bottom: 8px;
  padding: 12px;
  max-width: 220px;
  span {
    text-align: left;
    margin: auto 0;
    padding-left: 12px;
    font-weight: 500;
    text-transform: initial;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover,
    &:focus {
      background-color: #000;
      color: #fff;
      svg {
        fill: #fff;
      }
    }
  }
`;
const IconContainer = styled.div`
  min-width: 24px;
  height: 20px;
  margin: auto 0;
  svg {
    height: 20px;
    padding: 0;
    margin: 0;
  }
`;

const login = (provider) => {
  firebase.auth().signInWithRedirect(provider);
};

const logout = () => {
  firebase.auth().signOut();
};

const ButtonSignOut = () => (
  <>
    <ButtonContainer onClick={logout}>
      <IconContainer>
        <IconLeave />
      </IconContainer>
      <span>Sign out</span>
    </ButtonContainer>
  </>
);

const SignIn = () => {
  const [user, loading, error] = useAuthState(firebase);

  if (loading) {
    return (
      <>
        <Message>Initialising user..</Message>
        <IconLoading />
      </>
    );
  }
  if (error) {
    return (
      <>
        <Message>Something went wrong: {error}</Message>
      </>
    );
  }
  if (user) {
    return (
      <>
        <Message>Current User: {user.email}</Message>
        <ButtonContainer onClick={logout}>
          <IconContainer>
            <IconLeave />
          </IconContainer>
          <span>Sign out</span>
        </ButtonContainer>
      </>
    );
  }

  const signInOptions = [
    {
      provider: new firebase.auth.GoogleAuthProvider(),
      providerName: "Google",
      buttonIcon: <LogoGoogle />,
    },
    {
      provider: new firebase.auth.GithubAuthProvider(),
      providerName: "GitHub",
      buttonIcon: <LogoGithub />,
    },
    {
      provider: new firebase.auth.OAuthProvider("microsoft.com"),
      providerName: "Microsoft",
      buttonIcon: <LogoMicrosoft />,
    },
  ];

  return (
    <>
      <Message>Sign in to continue</Message>
      {signInOptions.map((option, i) => (
        <ButtonContainer
          key={`signin-${i}`}
          onClick={() => login(option.provider)}
        >
          <IconContainer>{option.buttonIcon}</IconContainer>
          <span>Sign in with {option.providerName}</span>
        </ButtonContainer>
      ))}
    </>
  );
};

export { SignIn, ButtonSignOut };
