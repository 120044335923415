import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import { ReactComponent as IconHome } from "../images/icons/home.svg";
// import { ReactComponent as IconSettings } from "../images/icons/settings.svg"
import { ReactComponent as IconRanking } from "../images/icons/award.svg";

const Container = styled.div`
  width: 100%;
  z-index: 1;
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: var(--box-shadow);
  background-color: #191e29;
  color: #fff;
  padding-bottom: env(safe-area-inset-bottom);
  @media (min-width: 768px) {
    width: 25%;
    height: 100%;
    top: 0;
  }
`;

const List = styled.ul`
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;
  @media (min-width: 768px) {
    display: block;
  }
`;

const ListItem = styled.li`
  width: 100%;
  margin: 0;
  min-width: 70px;
  max-width: 168px;
  &:first-of-type {
    margin-left: 0;
    @media (min-width: 768px) {
      margin-left: auto;
    }
  }
  display: block;
  @media (min-width: 768px) {
    margin: auto;
    max-width: 300px;
  }
`;

const StyledLink = styled(Link)`
  font-weight: medium;
  line-height: 1.4;
  text-decoration: none;
  margin: 8px;
  padding: 8px;
  background-color: ${(props) =>
    props.selected ? "var(--primary)" : "transparent"};
  border: 0px solid
    ${(props) => (props.selected ? "var(--primary)" : "var(--white)")};
  border-radius: var(--border-radius);
  color: var(--white);
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 0.875rem;
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: var(--primary-light);
      border-color: var(--primary-light);
      color: #000;

      text-decoration: none;
      svg {
        fill: #141821;
      }
    }
  }
  @media (min-width: 768px) {
    background-color: ${(props) =>
      props.selected ? "var(--primary)" : "#2b2b2b"};
    display: block;
    text-align: left;
    padding: 16px;
    margin: 16px;
    span {
      vertical-align: middle;
      padding: 8px;
    }
  }

  svg {
    height: 16px;
    font-size: 24px;
    vertical-align: middle;
    padding: 0;
    margin: 0;
    fill: var(--white);
  }
`;

const NavigationFloat = ({ leagueSlug, currentPage }) => (
  <>
    <Container>
      <List>
        <ListItem>
          <StyledLink
            selected={currentPage === "home"}
            to={`/leagues/${leagueSlug}/`}
          >
            <IconHome />
            <span>Home</span>
          </StyledLink>
        </ListItem>

        <ListItem>
          <StyledLink
            selected={currentPage === "ranking"}
            to={`/leagues/${leagueSlug}/ranking`}
          >
            <IconRanking />
            <span>Ranking</span>
          </StyledLink>
        </ListItem>
      </List>
    </Container>
  </>
);

export default NavigationFloat;
