import React from "react";
import styled from "styled-components";
import { SubTitle } from "../elements";

const UserImage = styled.img`
  border-radius: 50%;
  max-height: 64px;
  margin: 0 auto;
`;
const UserDetails = ({ user }) => {
  const lastSignInTime = new Date(parseInt(user.metadata.lastSignInTime));
  const creationTime = new Date(parseInt(user.metadata.creationTime));
  return (
    <>
      <SubTitle>
        Hi {user.displayName && user.displayName.split(" ")[0]}
      </SubTitle>
      <UserImage src={user.photoURL} />
      <p>Last sign in: {user.metadata.lastSignInTime}</p>
      <p>Signed up: {user.metadata.creationTime}</p>
    </>
  );
};

export default UserDetails;
