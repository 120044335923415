import React from "react";
import useSWR from "swr";
import { ReactComponent as LoadingImage } from "../images/oval.svg";

const useSWRwithParams = (url, params, options) => {
  const defaultOptions = { revalidateOnFocus: false };
  const fetchWithParams = ({ url, params }) =>
    fetch(url, params).then((response) => {
      // Check that the response is valid and reject an error
      // response to prevent subsequent attempt to parse json
      if (!response.ok) {
        return Promise.reject(response);
      }
      return response.json();
    });

  return useSWR(url, (url) => fetchWithParams({ url: url, params: params }), {
    ...defaultOptions,
    ...options,
  });
};

const swrError = (error) => (
  <div>
    {error.status === 504 ? "API server is down :(" : "Failed to load"}
    <br />
    {error.status
      ? `${error.status}: ${error.statusText}`
      : "NetworkError when attempting to fetch resource."}
  </div>
);

const swrLoading = (
  <div>
    <LoadingImage />
    <br />
    loading data..
  </div>
);

export { useSWRwithParams, swrError, swrLoading };
