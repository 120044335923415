import React from "react";
import styled from "styled-components";
import { useToggle } from "react-use";
import { ReactComponent as IconArrowDown } from "../images/icons/arrow-down.svg";
import { ReactComponent as IconArrowUp } from "../images/icons/arrow-up.svg";

const Badge = styled.div`
  margin: 8px;
  width: max-content;
  border-radius: var(--border-radius);
  padding: 4px auto;
  margin: 0 auto;
  text-transform: uppercase;
  text-align: center;
  font-size: 0.8rem;
  background-color: ${(props) =>
    props.isInfo ? "var(--grey)" : "var(--primary)"};
  display: flex;
  justify-content: center;
  span {
    color: #000;
    line-height: 1;
    padding: 4px 8px;
    font-size: 12px;
    margin: auto 0;
  }
  svg {
    border-radius: var(--border-radius);

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        cursor: pointer;
        background-color: #fff;
      }
    }
    height: 16px;
    padding: 8px;
  }
`;

const SeasonBadge = ({ season_number }) => (
  <>
    <Badge>
      <span>Season {season_number}</span>
    </Badge>
  </>
);

const SeasonBadgeSelector = ({ season_number }) => {
  const [toggle, setToggle] = useToggle(false);
  return (
    <>
      <Badge onClick={setToggle}>
        <span>Season {season_number}</span>
        {toggle ? <IconArrowUp /> : <IconArrowDown />}
      </Badge>
      {toggle ? (
        <>
          <Badge>
            <span>Season {season_number - 1}</span>
          </Badge>
        </>
      ) : null}
    </>
  );
};
export { SeasonBadge, SeasonBadgeSelector };
