import React from "react";
import styled from "styled-components";
import { useSWRwithParams, swrError, swrLoading } from "../helpers";

import Header from "../header";
import { Title, TitleContainer, StyledButtonLink } from "../elements";
import NavigationFloat from "../navigation-float";
import ManageSeasons from "./manage-seasons";
import ManagePlayers from "./manage-players";

const Container = styled.div`
  padding: 0 16px;
  color: #fff;
  text-align: center;

  margin-bottom: 80px;
  @media (min-width: 768px) {
    margin-left: 25%;
    margin-bottom: 16px;
  }
`;

const SeasonContainer = styled.div`
  p,
  ul {
    text-align: left;
  }
  .positive {
    margin-bottom: 8px;
    background-color: var(--green);
  }
  .negative {
    background-color: var(--red);
  }
`;

const LeaguesManage = ({ leagueSlug, user }) => {
  const { data, error } = useSWRwithParams(`/api/users/player`, {
    method: "GET",
    headers: {
      "X-User-Token": user.ya,
      "x-league-slug": leagueSlug,
    },
  });
  return (
    <>
      <Container>
        <Header user={user} />

        {error ? (
          swrError(error)
        ) : !data ? (
          swrLoading
        ) : !(data && data.data.current_player.role === "owner") ? (
          <p>Unauthorized</p>
        ) : (
          <>
            <TitleContainer>
              <Title>{data.data.league.name}</Title>
            </TitleContainer>
            <ManageSeasons user={user} leagueSlug={leagueSlug} />
            <ManagePlayers user={user} leagueSlug={leagueSlug} />
          </>
        )}
        <StyledButtonLink to={`/leagues/${leagueSlug}`}>
          Go back home
        </StyledButtonLink>
      </Container>
      <NavigationFloat leagueSlug={leagueSlug} currentPage="manage" />
    </>
  );
};

export default LeaguesManage;
