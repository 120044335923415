import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

import { Title } from "../../components/elements";
import Header from "../../components/header";
import SEO from "../../components/seo";
import Layout from "../../components/layout";
import LeagueForm from "../../components/forms/new-league";

const Container = styled.div`
  padding: 0 16px;
  color: #fff;
  text-align: center;
`;

const LeaguesSignup = ({ user }) => (
  <Layout>
    <SEO title="New League" />
    <Container>
      <Header user={user} showBack={true} />
      <LeagueForm user={user} />
    </Container>
  </Layout>
);

export default LeaguesSignup;
