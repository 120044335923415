import React from "react";
import { navigate } from "gatsby";
import styled from "styled-components";

import { useSWRwithParams, swrError, swrLoading } from "../helpers";
import { StyledButtonLink } from "../elements";
import Header from "../header";
import SEO from "../seo";
import Layout from "../layout";
import LeagueForm from "../forms/join-league";

const Container = styled.div`
  padding: 0 16px;
  color: #fff;
  text-align: center;
`;

const LeaguesJoin = ({ user, inviteToken = undefined }) => {
  const { data, error } = useSWRwithParams(
    `/api/leagues/check_token/${inviteToken}`,
    {
      method: "GET",
      headers: {
        "X-User-Token": user.ya,
      },
    }
  );

  return (
    <Layout>
      <SEO title="Join League" />
      <Container>
        <Header user={user} showBack={true} backUrl="/leagues" />
        {error ? (
          <div>
            <p>
              {error.status === 409
                ? "You have already joined this league"
                : error.status === 403
                ? "The invite token in this link is not valid"
                : "Something went wrong"}
            </p>
            <StyledButtonLink to="/leagues">
              Return to My Leagues
            </StyledButtonLink>
          </div>
        ) : !data ? (
          swrLoading
        ) : (
          <LeagueForm user={user} inviteToken={inviteToken} />
        )}
      </Container>
    </Layout>
  );
};

export default LeaguesJoin;
