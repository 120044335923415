import React from "react";
import styled from "styled-components";
import { navigate } from "gatsby";

import { StyledButton } from "../elements";

const ButtonContainer = styled.div`
  padding: 8px 16px;
  max-width: 600px;
  margin: 0 auto;
  p {
    text-align: left;
    margin-bottom: 16px;
  }
  .margin-bottom {
    margin-bottom: 8px;
  }
`;

const ButtonDeleteGame = ({ leagueSlug, user, gameId }) => {
  const [buttonState, setButtonState] = React.useState();
  const createNewSeason = () => {
    setButtonState("processing");
    fetch(`/api/games/${gameId}/delete`, {
      method: "GET",
      headers: {
        "X-User-Token": user.ya,
        "x-league-slug": leagueSlug,
      },
    })
      .then((response) => {
        if (!response.ok) {
          return Promise.reject(response);
        }
        // const responseData = response.json()
        setButtonState("success");
        navigate(`/leagues/${leagueSlug}/`);
      })
      .catch((error) => setButtonState("error"));
  };
  return (
    <ButtonContainer>
      {buttonState === "success" ? (
        <p>Game deleted successfully!</p>
      ) : buttonState === "error" ? (
        <p>Something went wrong :(</p>
      ) : buttonState === "processing" ? (
        <p>Processing..</p>
      ) : buttonState === "confirming" ? (
        <>
          <p>
            Deleting this game is an irreversible action and will remove the
            PureSkill impact from each player. Are you sure?
          </p>
          <div>
            <StyledButton
              className="margin-bottom"
              backgroundColor="var(--red)"
              onClick={createNewSeason}
            >
              Yes, delete this game
            </StyledButton>
          </div>
          <div>
            <StyledButton
              backgroundColor="var(--grey-dark)"
              onClick={() => setButtonState(null)}
            >
              Cancel
            </StyledButton>
          </div>
        </>
      ) : (
        <StyledButton
          backgroundColor="var(--red)"
          onClick={() => setButtonState("confirming")}
        >
          Delete game
        </StyledButton>
      )}
    </ButtonContainer>
  );
};

export default ButtonDeleteGame;
