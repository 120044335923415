import React from "react";
import firebase from "gatsby-plugin-firebase";
import { useTimeout } from "react-use";

import Layout from "./layout";
import Header from "./header";
import Footer from "./footer";
import { SignIn } from "./sign-in";
import { StyledButton } from "./elements";
import { ReactComponent as LoadingImage } from "../images/oval.svg";
import useAuthState from "./firebase/useAuthState";

export default (Component) => (props) => {
  const [isRegistered, setRegistered] = React.useState();
  const [isRegistering, setRegistering] = React.useState();
  const [registationError, setError] = React.useState();
  // const [user, setUser] = React.useState()
  const [isReady] = useTimeout(750);
  const [isSleeping, cancelSleeping, resetSleeping] = useTimeout(3000);
  const [user, initialising, error] = useAuthState(firebase);

  const registerUser = (user) => {
    resetSleeping();
    3;
    setRegistering(true);
    fetch(`/api/users/register`, {
      method: "POST",
      headers: {
        "X-User-Token": user.ya,
      },
    })
      .then((response) => {
        if (!response.ok) {
          return Promise.reject(response);
        }
        setRegistered(true);
      })
      .catch((registationError) => {
        setError(registationError);
        setRegistered(false);
      });
  };

  const resetAuth = () => {
    firebase.auth().signOut().then(setRegistered(null));
  };

  if (user && isRegistered) {
    return <Component user={user} {...props} />;
  } else if (user) {
    !isRegistering && registerUser(user);
    return (
      <Layout>
        <Header />
        <div>
          {isRegistered === false ? (
            <>
              <p>Something went wrong during registration.</p>
              <p>
                {registationError.status}: {registationError.statusText}
              </p>
              <StyledButton onClick={resetAuth}>Try again?</StyledButton>
            </>
          ) : isRegistering ? (
            <>
              <LoadingImage />
              <p>Talking to the mothership..</p>
              {isSleeping() ? (
                <p>Heroku is awaking from slumber (can take up to 15s)..</p>
              ) : null}
            </>
          ) : (
            <>
              <LoadingImage />
              <p>Logging you in..</p>
            </>
          )}
        </div>
        <Footer />
      </Layout>
    );
  } else {
    return (
      <Layout>
        <Header />
        <div>
          {isReady() === false || initialising ? (
            <>
              <LoadingImage />
              <p>Firing up the engines..</p>
            </>
          ) : (
            <SignIn />
          )}
        </div>
        <Footer />
      </Layout>
    );
  }
};
