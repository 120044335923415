import React from "react";
import styled from "styled-components";
import { useSWRwithParams, swrError, swrLoading } from "../helpers";
import { Badge, StyledButton, SubTitle } from "../elements";
import { SeasonBadge } from "../season-badge";

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;
const SeasonContainer = styled.div`
  padding: 8px 16px;
  margin-top: 8px;
  p,
  ul {
    text-align: left;
  }
  .margin-bottom {
    margin-bottom: 8px;
  }
`;

const SeasonDiv = styled.div`
  margin: 0 auto;
  margin-bottom: 16px;
  padding: 16px 8px;
  color: var(--white);
  background-color: #2b2b2b;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  text-align: center;
  max-width: 600px;
`;

const SeasonDivContainer = styled.div`
  /* margin: 0 auto; */
`;

const CreateSeasonButton = ({ leagueSlug, user, revalidate }) => {
  const [seasonState, updateSeasonState] = React.useState();
  const createNewSeason = () => {
    updateSeasonState("creating");
    fetch(`/api/seasons/create`, {
      method: "GET",
      headers: {
        "X-User-Token": user.ya,
        "x-league-slug": leagueSlug,
      },
    })
      .then((response) => {
        if (!response.ok) {
          return Promise.reject(response);
        }
        // const responseData = response.json()
        updateSeasonState("created");
        revalidate();
      })
      .catch((error) => updateSeasonState("error"));
  };
  return (
    <SeasonContainer>
      {seasonState === "created" ? (
        <p>New season started successfully!</p>
      ) : seasonState === "error" ? (
        <p>Something went wrong while creating a new season :(</p>
      ) : seasonState === "creating" ? (
        <p>Creating new season..</p>
      ) : seasonState === "confirming" ? (
        <>
          <p>Here is what will happen after starting a new season:</p>
          <ul>
            <li>The current season will be archived</li>
            <li>
              The PureSkill rating for all players in the new season will reset
            </li>
            <li>
              Games from the archived season have no impact on the PureSkill
              ratings in the new season
            </li>
            <li>
              All games, PureSkill ratings and the leaderboard of the current
              season can still be viewed
            </li>
          </ul>
          <p>Are you sure?</p>
          <div>
            <StyledButton
              className="margin-bottom"
              backgroundColor="var(--green)"
              onClick={createNewSeason}
            >
              Yes, start a new season
            </StyledButton>
          </div>
          <div>
            <StyledButton
              backgroundColor="var(--red)"
              onClick={() => updateSeasonState(null)}
            >
              Cancel
            </StyledButton>
          </div>
        </>
      ) : (
        <StyledButton onClick={() => updateSeasonState("confirming")}>
          Start a new season
        </StyledButton>
      )}
    </SeasonContainer>
  );
};

const RevertSeasonButton = ({ leagueSlug, user, revalidate }) => {
  const [seasonState, updateSeasonState] = React.useState();
  const createNewSeason = () => {
    updateSeasonState("reverting");
    fetch(`/api/seasons/revert`, {
      method: "GET",
      headers: {
        "X-User-Token": user.ya,
        "x-league-slug": leagueSlug,
      },
    })
      .then((response) => {
        if (!response.ok) {
          return Promise.reject(response);
        }
        updateSeasonState("reverted");
        revalidate();
      })
      .catch((error) => updateSeasonState("error"));
  };
  return (
    <SeasonContainer>
      {seasonState === "reverted" ? (
        <p>Successfully reverted to previous season!</p>
      ) : seasonState === "error" ? (
        <p>Something went wrong while reverting to previous season :(</p>
      ) : seasonState === "reverting" ? (
        <p>Revert to previous season..</p>
      ) : seasonState === "confirming" ? (
        <>
          <p>
            You can still undo this season as long as no games are played. This
            will reactive the previous season, and all games recorded will
            continue to count for that season.
          </p>
          <p>Are you sure?</p>
          <div>
            <StyledButton
              className="margin-bottom"
              backgroundColor="var(--red)"
              onClick={createNewSeason}
            >
              Yes, revert to previous season
            </StyledButton>
          </div>
          <div>
            <StyledButton
              backgroundColor="var(--grey-dark)"
              onClick={() => updateSeasonState(null)}
            >
              Cancel
            </StyledButton>
          </div>
        </>
      ) : (
        <StyledButton
          backgroundColor="var(--red)"
          onClick={() => updateSeasonState("confirming")}
        >
          Undo & revert to previous season
        </StyledButton>
      )}
    </SeasonContainer>
  );
};

const ManageSeasons = ({ leagueSlug, user }) => {
  const { data, error, revalidate } = useSWRwithParams(`/api/seasons`, {
    method: "GET",
    headers: {
      "X-User-Token": user.ya,
      "x-league-slug": leagueSlug,
    },
  });

  return (
    <>
      <SubTitle>Seasons</SubTitle>
      {error ? (
        swrError(error)
      ) : !data ? (
        swrLoading
      ) : !(data && data.data.current_player.role === "owner") ? (
        <p>Unauthorized</p>
      ) : (
        <>
          <SeasonDivContainer>
            {data.data.seasons
              .sort((a, b) => b.season_number - a.season_number)
              .map((season, i) => {
                const startDate =
                  season.started_at &&
                  new Date(season.started_at * 1000).toDateString();
                const endDate =
                  season.ended_at &&
                  new Date(season.ended_at * 1000).toDateString();
                return (
                  <SeasonDiv key={i}>
                    <SeasonBadge season_number={season.season_number} />
                    <Badge backgroundColor="var(--grey-x-dark)">
                      {season.games_played_count > 0
                        ? `${season.games_played_count} games played`
                        : "no games played yet"}
                    </Badge>
                    <FlexContainer>
                      <div>
                        <span>From</span>
                        <Badge backgroundColor="var(--grey-x-dark)">
                          {startDate}
                        </Badge>
                      </div>
                      <div>
                        <span>To</span>
                        <Badge
                          backgroundColor={
                            season.is_active
                              ? "var(--green)"
                              : "var(--grey-x-dark)"
                          }
                        >
                          {season.is_active ? "Active" : endDate}
                        </Badge>
                      </div>
                    </FlexContainer>
                    {season.is_active && season.games_played_count > 0 ? (
                      <CreateSeasonButton
                        leagueSlug={leagueSlug}
                        user={user}
                        revalidate={revalidate}
                      />
                    ) : null}

                    {season.is_active &&
                    season.games_played_count === 0 &&
                    season.season_number > 1 ? (
                      <RevertSeasonButton
                        leagueSlug={leagueSlug}
                        user={user}
                        revalidate={revalidate}
                      />
                    ) : null}
                  </SeasonDiv>
                );
              })}
          </SeasonDivContainer>
        </>
      )}
    </>
  );
};

export default ManageSeasons;
