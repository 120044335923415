import React from "react";
import styled from "styled-components";
import moment from "moment";
import { Link } from "gatsby";

import { Badge, UserImage } from "../elements";

const daysSinceDate = (dateUTC) => {
  const date = new Date(dateUTC * 1000);
  const diffTime = Date.now() - date;
  const duration = moment.duration(diffTime);

  return duration.humanize();
};

const Container = styled.div`
  margin: 0 auto;
  padding: 16px 8px;
  color: var(--primary);
  background-color: #2b2b2b;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  text-align: center;
  max-width: 600px;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Team = styled.div`
  margin: 8px auto;
  width: 33%;
  &.detail {
    width: inherit;
  }
  &.home {
    margin-right: 16px;
  }
  &.away {
    margin-left: 16px;
  }
  &:nth-child(2n) {
    .player {
      text-align: left;
    }
  }
`;

const Player = styled.div`
  font-size: 1rem;
  text-transform: uppercase;

  a {
    text-decoration: none;
    color: var(--primary);
    border-bottom: 1px dotted var(--primary);
  }
`;

const Score = styled.div`
  font-size: 2rem;

  a {
    text-decoration: none;
    color: var(--white);
    border-bottom: 1px dotted var(--grey);
  }
`;

const HistoryText = styled.div`
  display: block;
  font-size: 0.8rem;
  color: #ccc;
`;

const PlayerLink = styled.div`
  a {
    text-decoration: none;
    color: var(--primary);
    border-bottom: 1px dotted var(--primary);
  }
  &.right {
    text-align: right;
  }
  &.left {
    text-align: left;
  }
`;
const BadgeContainer = styled.div`
  margin: 4px auto;
  width: 100%;
  display: flex;
  height: 32px;
  .badge {
    line-height: 1;
    margin: auto;
  }
  &.right {
    justify-content: flex-end;
  }
  &.left {
    justify-content: flex-start;
  }
  .left {
    margin-left: 8px;
  }
  .right {
    margin-right: 8px;
  }
`;

const DetailHeader = styled.div`
  margin-top: 16px;
`;

const PlayerBadgeContainer = styled.div`
  margin-bottom: 16px;
`;

const ScoreBadge = ({
  playerName,
  delta_mu,
  photo_url,
  align = "right",
  playerLink,
}) => {
  const isPositive = delta_mu > 0;
  const formattedScore = `${isPositive > 0 ? "+" : ""}${delta_mu.toFixed()}`;
  const badgeColor = isPositive
    ? "var(--green)"
    : delta_mu < 0
    ? "var(--red)"
    : "var(--grey)";

  align === "left";
  const FormattedBadge = ({ align }) => (
    <>
      <Badge className={`badge ${align}`} backgroundColor={badgeColor}>
        {formattedScore}
      </Badge>
    </>
  );
  return (
    <PlayerBadgeContainer>
      <PlayerLink className={align}>
        <Link to={playerLink}>{playerName.toUpperCase()}</Link>
      </PlayerLink>
      <BadgeContainer className={align}>
        {align === "right" ? <FormattedBadge align={align} /> : null}
        <div className="image">
          <UserImage src={photo_url} />
        </div>
        {align === "left" ? <FormattedBadge align={align} /> : null}
      </BadgeContainer>
    </PlayerBadgeContainer>
  );
};

const GameResult = ({ game, leagueSlug, showDetail = false }) => (
  <>
    <Container>
      <FlexContainer>
        <Team>
          {game.team_home.map((player, i) => {
            return (
              <Player key={i}>
                <Link to={`/leagues/${leagueSlug}/players/${player.player_id}`}>
                  {player.name}
                </Link>
              </Player>
            );
          })}
        </Team>
        <Score>
          <Link to={`/leagues/${leagueSlug}/games/${game.game_id}`}>
            {game.score_home} - {game.score_away}
          </Link>
        </Score>
        <Team>
          {game.team_away.map((player, i) => {
            return (
              <Player key={i}>
                <Link to={`/leagues/${leagueSlug}/players/${player.player_id}`}>
                  {player.name}
                </Link>
              </Player>
            );
          })}
        </Team>
      </FlexContainer>
      <HistoryText>Played {daysSinceDate(game.played_at)} ago</HistoryText>
    </Container>
    {showDetail ? (
      <>
        <DetailHeader>
          <span>PureSkill impact</span>
        </DetailHeader>
        <FlexContainer>
          <Team className="detail home">
            {game.team_home.map((player, i) => {
              return (
                <>
                  <ScoreBadge
                    key={`home-${i}`}
                    playerLink={`/leagues/${leagueSlug}/players/${player.player_id}`}
                    playerName={player.name}
                    delta_mu={player.delta_mu}
                    photo_url={player.photo_url}
                  />
                </>
              );
            })}
          </Team>
          <Team className="detail away">
            {game.team_away.map((player, i) => {
              return (
                <ScoreBadge
                  key={`away-${i}`}
                  playerLink={`/leagues/${leagueSlug}/players/${player.player_id}`}
                  playerName={player.name}
                  delta_mu={player.delta_mu}
                  photo_url={player.photo_url}
                  align="left"
                />
              );
            })}
          </Team>
        </FlexContainer>
      </>
    ) : null}
  </>
);
export default GameResult;
