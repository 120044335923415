import React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { useAsyncFn } from "react-use";
import axios from "axios";

import { Title, TextInput, StyledButton } from "../elements";
import Header from "../header";

const Container = styled.div`
  padding: 0 16px;
  color: #fff;
  form {
    margin: 0 auto;
    text-align: center;

    div {
      padding: 8px 0;
    }
  }
`;

const Error = styled.div`
  color: #da4167;
  margintop: ".5rem";
`;

const HookForm = ({ leagueSlug }) => {
  const { register, handleSubmit, errors, watch } = useForm({
    mode: "onChange",
    submitFocusError: false,
  });

  const watchName = watch("name");
  const [state, submitData] = useAsyncFn(
    async (data) => {
      const response = await axios.post("/api/players/create", data, {
        headers: { "x-league-slug": leagueSlug },
      });
      return response;
    },
    [leagueSlug]
  );

  const onSubmit = (data) => submitData(data);

  const validateName = (name) => {
    return fetch(`/api/players/check/${name}`, {
      method: "GET",
      headers: {
        "x-league-slug": leagueSlug,
      },
    })
      .then((response) => response)
      .catch((error) => error);
  };

  return (
    <>
      <Container>
        <Header showBack={true} />
        <Title>Add new player</Title>
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <p>Choose a player name</p>
            <TextInput
              type="text"
              placeholder="Pick a name"
              name="name"
              value={user.displayName && user.displayName.split(" ")[0]}
              ref={register({
                required: "This field is required",
                pattern: {
                  value: /^[a-zA-Z]+$/i,
                  message: "Only use letters plz",
                },
                minLength: {
                  value: 3,
                  message: "Use at least 3 characters",
                },
                validate: async (name) => {
                  const c1 = name.match(/^[a-zA-Z]+$/i);
                  const c2 = name.length >= 3;
                  const c3 = await validateName(name);
                  return c3.status === 404
                    ? "API seems to be down :("
                    : (c1 && c2 && c3.ok) || "This name is already taken";
                },
              })}
            />
            {errors.name && <Error>{errors.name.message}</Error>}
          </div>

          <div>
            {state.loading ? (
              <div>Submitting...</div>
            ) : state.error ? (
              <>
                <div>Error: {state.error.message}</div>
                <StyledButton
                  type="submit"
                  disabled={
                    typeof watchName === "undefined" ||
                    Object.keys(errors).length > 0
                  }
                >
                  Submit
                </StyledButton>
              </>
            ) : state.value && state.ok ? (
              <>
                <div>Player registered</div>
                <StyledButton
                  onClick={() =>
                    typeof history !== "undefined" && history.go(-1)
                  }
                >
                  Return home
                </StyledButton>
              </>
            ) : (
              <StyledButton
                type="submit"
                disabled={
                  typeof watchName === "undefined" ||
                  Object.keys(errors).length > 0
                }
              >
                Submit
              </StyledButton>
            )}
          </div>
        </form>
      </Container>
    </>
  );
};

export default HookForm;
