import React from "react";
import styled from "styled-components";

import GameResult from "./game-result";

const ResultContainer = styled.div`
  margin: 16px auto;
`;

const GamesList = ({ games, leagueSlug, refresh }) => {
  return (
    <>
      {games.length > 0 ? (
        games.map((game, i) => (
          <ResultContainer key={`games-list-${i}`}>
            <GameResult game={game} leagueSlug={leagueSlug} />
          </ResultContainer>
        ))
      ) : (
        <p>No games played yet</p>
      )}
    </>
  );
};

export default GamesList;
