import React from "react";
import styled from "styled-components";
import { useSWRwithParams, swrError, swrLoading } from "../helpers";
import { useTable } from "react-table";
import { Link } from "gatsby";

import { SeasonBadge } from "../season-badge";
import Header from "../header";
import { Title, TitleContainer } from "../elements";
import NavigationFloat from "../navigation-float";

const Table = ({ columns, data }) => {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup, i) => (
          <tr key={`hg-${i}`} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, j) => (
              <th
                key={`th-${i}-${j}`}
                {...column.getHeaderProps({ className: column.className })}
              >
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} key={`tr-${i}`}>
              {row.cells.map((cell, j) => {
                return (
                  <td
                    {...cell.getCellProps({ className: cell.column.className })}
                    key={`td-${i}-${j}`}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const Container = styled.div`
  padding: 0 16px;
  margin-bottom: 80px;

  @media (min-width: 768px) {
    margin-left: 25%;
    margin-bottom: 16px;
  }

  table {
    margin: 0 auto;
    width: 100%;
    max-width: 600px;
    border: 0;
    padding: 16px;
    background-color: #2b2b2b;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
  }
  thead {
    color: var(--primary);
    th {
      padding: 8px;
      text-align: left;
    }
  }
  td {
    padding: 8px;
  }

  td.name {
    text-transform: uppercase;
    text-align: left;
    text-decoration: none;
    a {
      text-decoration: none;
      border-bottom: 1px dotted var(--grey);
      color: var(--primary);

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          color: var(--white);
        }
      }
    }
  }

  .number {
    text-align: right;
  }
`;

const LeaguesRanking = ({ leagueSlug, user }) => {
  const columns = React.useMemo(
    () => [
      {
        Header: "#",
        className: "name",
        accessor: (row) => row.rank,
      },
      {
        Header: "Name",
        className: "name",
        accessor: (row) => {
          return { name: row.name, id: row.player_id };
        },
        Cell: ({ cell: { value } }) => {
          return (
            <Link to={`/leagues/${leagueSlug}/players/${value.id}`}>
              {value.name}
            </Link>
          );
        },
      },
      {
        Header: "Games",
        className: "number",
        accessor: "games_played_count",
        Cell: ({ cell: { value } }) => value.toFixed(0),
      },
      {
        Header: "PureSkill",
        className: "number",
        accessor: "mu",
        Cell: ({ cell: { value } }) => value.toFixed(0),
      },
    ],
    []
  );
  const { data, error } = useSWRwithParams(`/api/players/stats`, {
    method: "GET",
    headers: {
      "X-User-Token": user.ya,
      "x-league-slug": leagueSlug,
    },
  });

  const playerStats =
    data && data.data.players.slice().sort((a, b) => b.mu - a.mu);
  playerStats && playerStats.forEach((row, i) => (row.rank = i + 1));

  return (
    <>
      <Container>
        <Header user={user} />
        {error ? (
          swrError(error)
        ) : !data ? (
          swrLoading
        ) : (
          <>
            <TitleContainer>
              <Title>Leaderboard</Title>
              <SeasonBadge season_number={data.data.season.season_number} />
            </TitleContainer>
            <Table columns={columns} data={playerStats} />
          </>
        )}
      </Container>
      <NavigationFloat leagueSlug={leagueSlug} currentPage="ranking" />
    </>
  );
};

export default LeaguesRanking;
