import React from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { navigate } from "gatsby";
import { useSWRwithParams, swrError, swrLoading } from "../helpers";
import styled from "styled-components";
import { useToggle, useAsyncFn } from "react-use";
import axios from "axios";

import PlayerSelection from "./player-selection";
import { Title, TextInput, StyledButton, StyledButtonLink } from "../elements";
import Header from "../header";
import NavigationFloat from "../navigation-float";

const Container = styled.div`
  padding: 0 16px;
  color: #fff;
  text-align: center;
  margin-bottom: 80px;
  @media (min-width: 768px) {
    margin-left: 25%;
    margin-bottom: 16px;
  }
`;

const FlexContainer = styled.div`
  @media (min-width: 320px) {
    display: flex;
    justify-content: center;
  }
  margin: 0 auto;
`;

const ScoreInput = styled(TextInput)`
  max-width: 64px;
  text-align: center;
  -moz-appearance: textfield; /*For FireFox*/

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
const Score = styled.div`
  text-align: center;
  padding: 16px;
`;
const Error = styled.div`
  color: var(--red);
  margintop: ".5rem";
`;

const HiddenUl = styled.ul`
  display: none;
`;

const TeamContainer = styled.div`
  padding: 0 8px;
`;

const PlayerContainer = styled.div`
  display: flex;
  margin: 16px auto;
  justify-content: flex-start;
`;

const PlayerImage = styled.img`
  margin: 0;
  padding: 0;
  background-color: #2b2b2b;
  border: 4px solid
    ${(props) =>
      props.selected
        ? props.teamSide === "away"
          ? "#e8c547"
          : "#cdedf6"
        : "#2b2b2b"};
  border-radius: 50%;
  height: 48px;
  width: 48px;
`;

const Name = styled.div`
  margin: auto 8px;
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: 500;
`;

const HookForm = ({ user, players, leagueSlug }) => {
  const { register, control, handleSubmit, errors, formState, watch } = useForm(
    {
      mode: "onBlur",
      submitFocusError: false,
      defaultValues: {
        players: [],
      },
    }
  );
  const [state, submitData] = useAsyncFn(
    async (data) => {
      data.players.forEach((o) => (o.id = o.player_id));
      const response = await axios.post("/api/games/create", data, {
        headers: {
          "x-league-slug": leagueSlug,
          "X-User-Token": user.ya,
        },
      });
      return response;
    },
    [leagueSlug]
  );
  state.value &&
    state.value.status === 200 &&
    navigate(`/leagues/${leagueSlug}/`);

  const onSubmit = (data) => submitData(data);

  const watchScoreHome = watch("score_home");
  const watchScoreAway = watch("score_away");

  const { fields, append, remove } = useFieldArray({
    control,
    name: "players",
  });

  const [on_home, toggle_home] = useToggle(false);
  const [on_away, toggle_away] = useToggle(false);

  const homePlayers = fields.slice().filter((o) => o.team_side == "home");
  const awayPlayers = fields.slice().filter((o) => o.team_side == "away");

  const submitIsDisabled =
    fields.length <= 1 ||
    on_home ||
    on_away ||
    Object.keys(errors).length > 0 ||
    formState.isSubmitting ||
    typeof watchScoreHome === "undefined" ||
    watchScoreHome.length < 1 ||
    typeof watchScoreAway === "undefined" ||
    watchScoreAway.length < 1;
  return (
    <>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <HiddenUl>
          {fields.map((item, index) => {
            return (
              <li key={item.id}>
                <input
                  type="hidden"
                  name={`players[${index}].name`}
                  defaultValue={`${item.name}`}
                  ref={register({ required: true })}
                />
                <input
                  type="hidden"
                  name={`players[${index}].player_id`}
                  defaultValue={`${item.player_id}`}
                  ref={register({ required: true })}
                />
                <input
                  type="hidden"
                  name={`players[${index}].team_side`}
                  defaultValue={`${item.team_side}`}
                  ref={register({ required: true })}
                />
              </li>
            );
          })}
        </HiddenUl>
        <FlexContainer>
          <TeamContainer>
            <StyledButton type="button" onClick={toggle_home}>
              {homePlayers.length > 0 ? "Edit" : "Choose"} home team
            </StyledButton>
            {on_home ? (
              <PlayerSelection
                players={players}
                fields={fields}
                append={append}
                remove={remove}
                toggle={toggle_home}
                team_side="home"
              />
            ) : null}

            <div>
              {homePlayers.map((item) => {
                return (
                  <PlayerContainer key={`home-player-${item.id}`}>
                    <PlayerImage
                      src={item.photo_url}
                      selected={1}
                      teamSide={item.team_side}
                    />
                    <Name>{item.name}</Name>
                  </PlayerContainer>
                );
              })}
            </div>
            <Score>
              <p>Home score</p>
              <ScoreInput
                disabled={on_home || on_away}
                type="number"
                inputmode="numeric"
                pattern="[0-9]*"
                placeholder="Score"
                name="score_home"
                ref={register({
                  required: "This field is required",
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Only numbers allowed here",
                  },
                  min: {
                    value: 0,
                    message: "No negative scores",
                  },
                  max: {
                    value: 50,
                    message: ">50 score? Impossibru!",
                  },
                })}
              />
              {errors.score_home && <Error>{errors.score_home.message}</Error>}
            </Score>
          </TeamContainer>
          <TeamContainer>
            <StyledButton type="button" onClick={toggle_away}>
              {awayPlayers.length > 0 ? "Edit" : "Choose"} away team
            </StyledButton>
            {on_away ? (
              <PlayerSelection
                players={players}
                fields={fields}
                append={append}
                remove={remove}
                toggle={toggle_away}
                team_side="away"
              />
            ) : null}
            <div>
              {awayPlayers.map((item) => {
                return (
                  <PlayerContainer key={`away-player-${item.id}`}>
                    <PlayerImage
                      src={item.photo_url}
                      selected={1}
                      teamSide={item.team_side}
                    />

                    <Name>{item.name}</Name>
                  </PlayerContainer>
                );
              })}
            </div>
            <Score>
              <p>Away score</p>
              <ScoreInput
                disabled={on_home || on_away}
                type="number"
                inputmode="numeric"
                pattern="[0-9]*"
                placeholder="Score"
                name="score_away"
                ref={register({
                  required: "This field is required",
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Only numbers allowed here",
                  },
                  min: {
                    value: 0,
                    message: "No negative scores",
                  },
                  max: {
                    value: 50,
                    message: ">50 score? Impossibru!",
                  },
                })}
              />
              {errors.score_away && <Error>{errors.score_away.message}</Error>}
            </Score>
          </TeamContainer>
        </FlexContainer>

        <div>
          {state.loading ? (
            <div>Submitting...</div>
          ) : state.error ? (
            <div>Error: {state.error.message}</div>
          ) : state.value && state.value.status === 200 ? (
            <>
              <div>Game recorded</div>
              <StyledButtonLink to={`/leagues/${leagueSlug}/`}>
                Return home
              </StyledButtonLink>
            </>
          ) : (
            <StyledButton type="submit" disabled={submitIsDisabled}>
              Submit
            </StyledButton>
          )}
        </div>
      </form>
    </>
  );
};

// <GameForm players={data.data.players} leagueSlug={leagueSlug} />
const NewGame = ({ user, leagueSlug }) => {
  const { data, error } = useSWRwithParams(`/api/players`, {
    method: "GET",
    headers: {
      "x-league-slug": leagueSlug,
      "X-User-Token": user.ya,
    },
  });

  return (
    <>
      <Container>
        <Header user={user} showBack={true} />
        <Title>Add new game</Title>
        {error ? (
          swrError(error)
        ) : !data ? (
          swrLoading
        ) : (
          <>
            <HookForm
              user={user}
              players={data.data.players.sort((a, b) =>
                a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
              )}
              leagueSlug={leagueSlug}
            />
          </>
        )}
      </Container>
      <NavigationFloat leagueSlug={leagueSlug} />
    </>
  );
};
export default NewGame;
