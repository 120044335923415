import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import copy from "copy-to-clipboard";
import useWebShare from "react-use-web-share";
import { useToggle } from "react-use";

import { SeasonBadge } from "../season-badge";
import { useSWRwithParams, swrError, swrLoading } from "../helpers";
import GamesList from "../games/games-list";
import Header from "../header";
import { Title, FloatButtonLink, StyledButtonLink } from "../elements";
import NavigationFloat from "../navigation-float";
import { ReactComponent as IconShare } from "../../images/icons/share.svg";
import { ReactComponent as IconPlus } from "../../images/icons/plus-square.svg";

const Container = styled.div`
  margin-bottom: 140px;
  padding: 0 16px;
  color: #fff;
  @media (min-width: 768px) {
    margin-left: 25%;
    margin-bottom: 80px;
  }
`;

const TitleContainer = styled.div`
  margin-bottom: 16px;
  h1 {
    margin: 0 auto;
  }
`;

const SubTitle = styled.h2`
  margin-top: 8px;
  font-size: 1.5rem;
  letter-spacing: -0.003em;
  text-align: center;
`;

const ButtonContainer = styled.div`
  position: fixed;
  z-index: 110;
  bottom: calc(80px + env(safe-area-inset-bottom));
  right: 16px;

  @media (min-width: 768px) {
    bottom: 16px;
    right: 77.5%;
    width: 20%;
  }
`;

const InviteContainer = styled.div`
  margin-bottom: 16px;
  p {
    margin: 8px auto;
  }
`;
const InputGroup = styled.div`
  display: flex;
  justify-content: center;
  input {
    appearance: none;
    margin: 0;
    color: #6a737d;
    border: 1px solid #d1d5da;
    border-right: 0;
    border-radius: 3px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    max-width: 300px;
    width: calc(100% - 64px);
    padding: 8px;
    background-color: #fff;
    font-size: 12px;
    line-height: 1;
  }
  button {
    appearance: none;
    margin: 0;
    border: 1px solid #d1d5da;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    cursor: pointer;
    background-color: #f0f0f0;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        border-color: #999;
        background-color: #fff;
      }
    }

    svg {
      height: 16px;
    }
  }
`;

const LeaguesHome = ({ leagueSlug, user }) => {
  const { data, error } = useSWRwithParams(`/api/games`, {
    method: "GET",
    headers: {
      "x-league-slug": leagueSlug,
      "X-User-Token": user.ya,
    },
  });
  const { isSupported, share } = useWebShare();
  const [isCopied, setIsCopied] = useToggle();

  const shareURL =
    data && `https://coleague.app/leagues/join/${data.data.league.token}`;
  const shareLink = () => {
    if (isSupported) {
      share({
        title: "CoLeague",
        text: `Join ${data.data.league.name} at CoLeague!`,
        url: shareURL,
      });
    } else {
      copy(shareURL);
      setIsCopied(true);
    }
  };

  return (
    <>
      <Container>
        <Header user={user} />
        {error && error.status === 404 ? (
          <>
            <p>League does not exist</p>
            <FloatButtonLink to="/leagues">
              Return to my leagues
            </FloatButtonLink>
          </>
        ) : error ? (
          swrError(error)
        ) : !data ? (
          swrLoading
        ) : (
          <>
            <TitleContainer>
              <Title>{data.data.league.name}</Title>

              <SeasonBadge season_number={data.data.season.season_number} />
            </TitleContainer>
            {data.data.current_player.role === "owner" ? (
              <>
                <InviteContainer>
                  <p>Invite players to your league</p>
                  <InputGroup>
                    <input
                      id="foo"
                      value={`https://coleague.app/leagues/join/${data.data.league.token}`}
                      readOnly
                    />
                    <button onClick={shareLink}>
                      <IconShare />
                    </button>
                  </InputGroup>
                  {isCopied ? <p>Copied to clipboard</p> : null}
                </InviteContainer>
                <StyledButtonLink to={`/leagues/${leagueSlug}/manage`}>
                  Manage league
                </StyledButtonLink>
              </>
            ) : null}
            <ButtonContainer>
              <FloatButtonLink to={`/leagues/${leagueSlug}/new/game`}>
                <IconPlus />
                <span>Add game</span>
              </FloatButtonLink>
            </ButtonContainer>
            <SubTitle>Latest games</SubTitle>
            <GamesList games={data.data.games} leagueSlug={leagueSlug} />
          </>
        )}
      </Container>
      <NavigationFloat leagueSlug={leagueSlug} currentPage="home" />
    </>
  );
};

LeaguesHome.propTypes = {
  leagueSlug: PropTypes.string,
};
export default LeaguesHome;
