import React from "react";
import ReactEcharts from "echarts-for-react";
import echarts from "echarts/lib/echarts";
import "echarts/lib/chart/line";

const contrastColor = "#e0e0e0";

echarts.registerTheme("dark", {
  backgroundColor: "#121212",
  tooltip: {
    axisPointer: {
      label: {
        backgroundColor: "#222",
      },
      lineStyle: {
        type: "dotted",
        color: contrastColor,
      },
      crossStyle: {
        color: contrastColor,
      },
    },
  },
  legend: {
    textStyle: {
      color: contrastColor,
    },
  },
  textStyle: {
    color: contrastColor,
  },
  title: {
    textStyle: {
      color: contrastColor,
    },
  },
  valueAxis: {
    axisLine: {
      lineStyle: {
        color: contrastColor,
      },
    },
    axisTick: {
      lineStyle: {
        color: contrastColor,
      },
    },
    axisLabel: {
      textStyle: {
        color: contrastColor,
      },
    },
    splitLine: {
      lineStyle: {
        type: "dotted",
        color: "#222",
      },
    },
    splitArea: {
      areaStyle: {
        color: contrastColor,
      },
    },
  },

  line: {
    symbol: "circle",
    symbolSize: 1,
    sampling: "average",
    smooth: false,
    // delay for later data is larger
    animationEasing: "cubicInOut",
    itemStyle: {
      color: contrastColor,
    },
    lineStyle: {
      color: "#757aff",
    },
  },
});

const EloEvolutionChart = ({ games, playerRating }) => {
  const deltaMus = [0, ...games.map((game) => game.delta_mu), 0].reverse();
  const deltaSigmas = [
    0,
    ...games.map((game) => game.delta_sigma),
    0,
  ].reverse();
  let currentSigma = playerRating.sigma;
  const evoSigma = deltaSigmas
    .map((deltaSigma) => (currentSigma -= deltaSigma))
    .reverse()
    .slice(1);

  let currentMu = playerRating.mu;
  const evoMu = deltaMus
    .map((deltaMu) => (currentMu -= deltaMu))
    .reverse()
    .slice(1);

  const evoUpperBounds = evoMu.map((mu, i) => 2 * evoSigma[i]);
  const evoLowerBounds = evoMu.map((mu, i) => mu - evoSigma[i]);

  return (
    <>
      {games.length > 0 ? (
        <ReactEcharts
          theme={"dark"}
          option={{
            title: {
              text: "PureSkill evolution",
            },
            tooltip: {
              trigger: "none",
              axisPointer: {
                type: "cross",
              },
            },
            xAxis: {
              nameGap: 30,
              name: "Games played",
              nameLocation: "center",
              type: "category",
              data: evoMu.map((o, i) => i),
              axisPointer: {
                label: {
                  formatter: (params) => "Game #" + params.value,
                },
              },
            },
            grid: {
              left: "40",
              right: "10",
              bottom: "45",
            },
            yAxis: {
              name: "PureSkill",
              type: "value",
              scale: true,
              axisPointer: {
                label: {
                  precision: 1,
                },
              },
            },
            series: [
              {
                name: "lower bound",
                type: "line",
                data: evoLowerBounds,
                stack: "confidence-band",
                lineStyle: {
                  normal: {
                    opacity: 0,
                  },
                },
                symbol: "none",
              },
              {
                name: "sigma",
                type: "line",
                data: evoUpperBounds,
                stack: "confidence-band",
                lineStyle: {
                  normal: {
                    opacity: 0,
                  },
                },
                smooth: false,
                areaStyle: {
                  normal: {
                    color: "#333",
                  },
                },
                symbol: "none",
              },
              {
                name: "average",
                type: "line",
                data: evoMu,
              },
            ],
          }}
          style={{
            height: "100%",
            minHeight: "240px",
            margin: "8px auto",
            padding: "16px",
            backgroundColor: "var(--primary-dark)",
            borderRadius: "4px",
            boxShadow: "var--(box-shadow)",
          }}
        />
      ) : null}
    </>
  );
};

export default EloEvolutionChart;
