import React from "react";
import styled from "styled-components";

import UserLeagues from "../users/user-leagues";
import { ButtonSignOut } from "../sign-in";
import Header from "../header";
import Footer from "../footer";

const Container = styled.div`
  padding: 0 16px;
  color: #fff;
  text-align: center;
`;

const UserAccount = ({ user }) => (
  <>
    <Container>
      <Header headerUrl="/" user={user} />
      <UserLeagues user={user} />
      <ButtonSignOut />
      <Footer />
    </Container>
  </>
);

export default UserAccount;
