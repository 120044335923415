import React from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { useAsyncFn } from "react-use";
import axios from "axios";

import { TextInput, StyledButton, StyledButtonLink } from "../elements";

const Error = styled.div`
  color: #da4167;
  margintop: ".5rem";
`;

const JoinLeagueForm = ({ inviteToken = undefined, user }) => {
  const { register, handleSubmit, errors, watch } = useForm({
    mode: "onChange",
    submitFocusError: false,
    defaultValues: {
      inviteToken: inviteToken,
      playerName: user.displayName && user.displayName.split(" ")[0],
    },
  });

  const watchToken = watch("inviteToken");
  const watchName = watch("playerName");
  const validateName = (name) => {
    return fetch(`/api/players/check/${name}`, {
      method: "GET",
      headers: {
        "X-User-Token": user.ya,
        "x-invite-token": watchToken,
      },
    })
      .then((response) => response)
      .catch((error) => error);
  };

  const [state, submitData] = useAsyncFn(
    async (data) => {
      const response = await axios.post("/api/leagues/join", data, {
        headers: {
          "X-User-Token": user.ya,
        },
      });
      return response;
    },
    [user]
  );

  const onSubmit = (data) => submitData(data);

  return (
    <>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <p>Enter your invite token</p>
          <TextInput
            type="text"
            placeholder="Invite token"
            name="inviteToken"
            ref={register({
              required: "This field is required",
              pattern: {
                value: /^[a-z|\d]+$/i,
                message: "Invalid token format",
              },
              minLength: {
                value: 32,
                message: "Invalid token format",
              },
              maxLength: {
                value: 32,
                message: "Invalid token format",
              },
            })}
          />
          {errors.inviteToken && <Error>{errors.inviteToken.message}</Error>}
        </div>

        <div>
          <p>Choose a player name</p>
          <TextInput
            type="text"
            placeholder="Pick a name"
            name="playerName"
            ref={register({
              required: "This field is required",
              pattern: {
                value: /^[a-zA-Z]+$/i,
                message: "Only use letters plz",
              },
              minLength: {
                value: 3,
                message: "Use at least 3 characters",
              },
              validate: async (name) => {
                const c1 = name.match(/^[a-zA-Z]+$/i);
                const c2 = name.length >= 3;
                const c3 = watchToken && !errors.inviteToken;
                const c4 = await validateName(name);
                return c4.status === 404 || c4.status === 504
                  ? "API seems to be down :("
                  : c4.status === 401
                  ? "Invalid invite token"
                  : !c3
                  ? "Invite token not filled in"
                  : (c1 && c2 && c3 && c4.ok) || "This name is already taken";
              },
            })}
          />
          {errors.playerName && <Error>{errors.playerName.message}</Error>}
        </div>

        <div>
          {state.loading ? (
            <div>Submitting...</div>
          ) : state.error ? (
            <>
              <div>Error: {state.error.message}</div>
              <StyledButton
                type="submit"
                disabled={
                  typeof watchToken === "undefined" ||
                  Object.keys(errors).length > 0
                }
              >
                Submit
              </StyledButton>
            </>
          ) : state.value && state.value.status === 200 ? (
            <>
              <div>League {state.value.data.data.league.name} created</div>
              <StyledButtonLink
                to={`/leagues/${state.value.data.data.league.slug}`}
              >
                Go to league
              </StyledButtonLink>
            </>
          ) : (
            <StyledButton
              type="submit"
              disabled={
                typeof watchToken === "undefined" ||
                Object.keys(errors).length > 0
              }
            >
              Submit
            </StyledButton>
          )}
        </div>
      </form>
    </>
  );
};

export default JoinLeagueForm;
