import React from "react";
import styled from "styled-components";

const Container = styled.div`
  margin: 0;
  margin-top: 16px;

  @media (min-width: 768px) {
    margin: auto;
    width: 50%;
  }
`;
const CardContainer = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  &:not(:last-child) {
    margin-bottom: 4px;
  }
`;

const Card = styled.div`
  width: 33.3%;
  &:nth-child(2n) {
    margin-left: 4px;
    margin-right: 4px;
  }
  padding: 8px;
  border-radius: var(--border-radius);
  background-color: var(--primary-dark);
  @media (min-width: 768px) {
    padding: 28px 8px;
  }
`;

const CardTitle = styled.div`
  font-size: 2rem;
  font-weight: 600;
  color: var(--white);
`;

const CardText = styled.div`
  font-size: 0.9rem;
  color: #999;
`;

const PlayerStats = ({ data }) => {
  const toPercentage = (number, round = 0) =>
    (number * 100).toFixed(round) + "%";
  const formatPositive = (number) => (number > 0 ? "+" : null) + number;
  const stats = {
    games_won_percentage: data.games_won_count / data.games_played_count,
    goals_scored_avg: data.goals_scored_sum / data.games_played_count,
    goals_aggregate: data.goals_scored_sum - data.goals_conceded_sum,
  };

  return (
    <>
      <Container>
        <CardContainer>
          <Card>
            <CardText>Win rate</CardText>
            <CardTitle>{toPercentage(stats.games_won_percentage)}</CardTitle>
          </Card>
          <Card>
            <CardText>Goal rate</CardText>
            <CardTitle>{stats.goals_scored_avg.toFixed(1)}</CardTitle>
          </Card>
          <Card>
            <CardText>Goal diff</CardText>
            <CardTitle>{formatPositive(stats.goals_aggregate)}</CardTitle>
          </Card>
        </CardContainer>
        <CardContainer>
          <Card>
            <CardText>Games played</CardText>
            <CardTitle>{data.games_played_count}</CardTitle>
          </Card>
          <Card>
            <CardText>Won</CardText>
            <CardTitle>{data.games_won_count}</CardTitle>
          </Card>
          <Card>
            <CardText>Lost</CardText>
            <CardTitle>{data.games_lost_count}</CardTitle>
          </Card>
        </CardContainer>
      </Container>
    </>
  );
};

export default PlayerStats;
