import React from "react";
import styled from "styled-components";

import Layout from "../layout";
import SEO from "../seo";
import Header from "../header";
import { StyledButtonLink } from "../elements";
import { ButtonSignOut } from "../sign-in";
import UserDetails from "./user-details";

const Container = styled.div`
  text-align: center;
  padding: 0 16px;
`;

const ButtonContainer = styled.div`
  margin: 8px auto;
  max-width: 300px;
`;

const UserAccount = ({ user }) => (
  <>
    <Layout>
      <SEO title="My Account" />
      <Container>
        <Header user={user} />
        <UserDetails user={user} />
        <ButtonContainer>
          <StyledButtonLink to="/leagues">My leagues</StyledButtonLink>
        </ButtonContainer>
        <ButtonContainer>
          <ButtonSignOut />
        </ButtonContainer>
      </Container>
    </Layout>
  </>
);

export default UserAccount;
